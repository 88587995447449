<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :width="type === 'auth' ? '500px' : '1000px'"
      @closed="closeModal" @open="openModal">
        <ch-form :render-option="option" :model="modalForm" :rules=rules
                 :props="{labelWidth: '120px' , paddingRight: '20px', paddingLeft: '80px'}"
                 :splice="splice" ref="modalForm">
          <template #time>
            <el-date-picker
              v-model="modalForm.entryTime"
              type="date" size="small"
              placeholder="请输入入职日期">
            </el-date-picker>
          </template>
        </ch-form>

        <span slot="footer" class="flex_con" v-if="type === 'watch'">
          <ch-button @click="closeModal" type="cancel">关闭</ch-button>
        </span>
        <span slot="footer" class="flex_con" v-else>
          <ch-button @click="closeModal" type="cancel">取消</ch-button>
          <ch-button
            @click="handleModal('modalForm')"
            :loading="isButtonLoading"
            type="ok">确定</ch-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import {phone} from "@/utils/validator";
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      title: '',
      editId: '',
      modalForm: {},
      isOpenModal: false,
      isButtonLoading: false,
      type: '',
      roleList: [],
      splice: []
    }
  },
  computed: {
    rules: function() {
      if(this.type === 'add' || this.type === 'edit') {
        return {
          name: [{required: true, message: "请输入员工姓名", trigger: ["blur", "change"]}],
          iphone: [{required: true,pattern: phone, message: "请输入手机号码", trigger: ["blur", "change"]}],
          dept: [{required: true, message: "请输入部门", trigger: ["blur", "change"]}],
          post: [{required: true, message: "请输入岗位", trigger: ["blur", "change"]}],
          entryTime: [{required: true, message: "请选择日期", trigger: ["blur", "change"]}],
          email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        }
      } else {
        return {}
      }
    },
    option: function () {
      if (this.type === 'watch' && this.isOpenModal) {
        return [
          {type: 'text', label: '用户ID：', prop: 'userId'},
          {type: 'text', label: '用户状态：', prop: 'status'},
          {type: 'text', label: '用户姓名：', prop: 'name'},
          {type: 'text', label: '手机号码：', prop: 'iphone'},
          {type: 'text', label: '身份证号：', prop: 'idCard'},
          {type: 'text', label: '入职时间：', prop: 'entryTime'},
          {type: 'text', label: '电子邮箱：', prop: 'email'},
          {type: 'text', label: '部门：', prop: 'deptName'},
          {type: 'text', label: '岗位：', prop: 'postName'},
          {type: 'text', label: '角色：', prop: 'roleName'},
          {type: 'text', label: '地址：', prop: 'address'},
          {type: 'text', label: '备注：', prop: 'remark'},
        ]
      }else if( this.type === 'auth') {
        return [
          {type: 'slot', slotName: 'auth', label: '验证码授权：', labelWidth: '90px'}
        ]
      } else {
        return [
          {type: 'input', label: '员工姓名：', prop: 'name', placeholder: "请输入姓名"},
          {type: 'select',  label: '所属部门：', prop: 'dept',
            name: "deptName", value: "id", option: this.deptOption},
          {type: 'select',  label: '岗位：', prop: 'post',
            name: "postName", value: "id", option: this.postOption},
          {type: 'input', label: '电子邮箱：', prop: 'email', placeholder: "请输入电子邮箱"},
          {type: 'input', label: '手机号码：', prop: 'iphone', placeholder: "请输入手机账号"},
          {type: 'input', label: '身份证号码：', prop: 'idCard', placeholder: "请输入身份证号"},
          {type: 'input', label: '联系地址：', prop: 'address', placeholder: "请输入联系地址"},
          {type: 'slot', slotName: 'time', label: '入职日期：',prop:'entryTime'},
          {type: 'input', label: '备注信息：', prop: 'remark', placeholder: "请输入备注信息"},
        ]
      }
    },
    ...mapGetters('system', ['deptOption', 'postOption'])
  },
  mounted() {

  },
  methods: {
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    openWatch(row) {
      this.splice = [7, 7]
      this.title = '查看员工信息'
      this.type = 'watch'
      this.modalForm = JSON.parse(JSON.stringify(row))
      this.modalForm.status = row.status == '0' ? '正常' : '停用'
      this.isOpenModal = true
    },
    openAdd() {
      this.splice = [5, 5]
      this.title = '新增员工信息'
      this.type = 'add'
      this.modalForm = {
        address: "", //地址
        email: "", //邮箱
        idCard: "", //身份证号
        entryTime: "", //入职
        name: "",
        iphone: "", //电话
        remark: "", //备注
        status: null,
      }
      this.isOpenModal = true
    },
    openEdit(row) {
      this.splice = [5, 5]
      this.title = '修改员工信息'
      this.type = 'edit'
      this.editId = row.userId
      this.modalForm = {
        ...JSON.parse(JSON.stringify(row)),
      }
      this.isOpenModal = true
      delete this.modalForm.isAdmin
    },
    // 打开授权
    openAuth(row) {
      this.splice = []
      this.title = '发送短信授权'
      this.type = 'auth'
      this.modalForm = {
        userCode: '',
        iphone: row.iphone
      }
      this.isOpenModal = true
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isButtonLoading = true
          let parma = {
            ...this.modalForm,
            entryTime: dayjs(this.modalForm.entryTime).format('YYYY-MM-DD'),
          }
          if(this.type === 'add') {
            this.$curl.post("/hm/staff/add", parma)
              .then(() => {
                this.$message.success("新增成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getUserOption(true)
              },).catch(() => this.isButtonLoading = false)
          }else if(this.type === 'edit'){
            this.$curl.post("/hm/staff/edit", parma)
              .then(() => {
                this.$message.success("编辑成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getUserOption(true)
              }).catch(() => this.isButtonLoading = false)
          }
        }
      });
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.editId = ''
      this.isOpenModal = false
      this.isButtonLoading = false
    },
  },
}
</script>